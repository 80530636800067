import { Injectable } from '@angular/core';
import { BaseService } from '../../../_util/base.service';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../../_type/api-response.type';
import { Role } from '../model/role.model';
import _utils from '../../../_util/_utils';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RoleService extends BaseService {

    private readonly apiPath = _utils.genApiPath('role');

    constructor(
        logger: NGXLogger,
        private readonly http: HttpClient,
    ) { super(logger); }

    // POST END-POINTS

    // @Post()
    // TODO newRole(@Body() role: NewRoleDto) {
    //     return await this.roleService.newRole(role);
    // }

    // PUT END-POINTS

    // @Put('/:_id')
    // TODO updateRole(@Param() params: ObjectIdDto, @Body() role: UpdateRoleDto) {
    //     return await this.roleService.updateRole(params._id, role);
    // }

    find(companyId: string) {
        return this.http.put<ApiResponse<Role[]>>(this.apiPath + '/find', { company: { _id: companyId }, isTemplate: false });
    }
    // GET END-POINTS

    getAll() {
        /*const _inMem = this._inMemory<ApiResponse<Role[]>>('getAll');
        if (_inMem) {
            return of(_inMem);
        }*/
        return this.http.get<ApiResponse<Role[]>>(
            this.apiPath
        ).pipe(
            tap(roles => this._inMemory('getAll', roles)),
            map(roles => {
                return roles;
            })
        );
    }

    getByCompany() {
        const _inMem = this._inMemory<ApiResponse<Role[]>>('getByCompany');
        if (_inMem) {
            return of(_inMem);
        }
        return this.http.get<ApiResponse<Role[]>>(
            this.apiPath + '/company'
        ).pipe(
            tap(roles => this._inMemory('getByCompany', roles))
        );
    }

    get(_id: string) {
        return this.http.get<ApiResponse<Role>>(
            this.apiPath + '/' + _id
        );
    }

    getTemplateByName(name: string) {
        return this.http.get<ApiResponse<Role>>(
            this.apiPath + '/' + name
        );
    }

    // DELETE END-POINTS

    // @Delete('/:_id')
    // TODO deleteRole(@Param() params: ObjectIdDto) {
    //     return await this.roleService.markAsDeleted(params._id);
    // }
}
