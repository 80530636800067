import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseClass } from '../_util/base.class';
export abstract class BaseGuard extends BaseClass implements CanActivate, CanActivateChild {
    abstract _verify(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean>;
    canActivate(route, state) {
        return this._verify(route, state);
    }
    canActivateChild(route, state) {
        return this._verify(route, state);
    }
}
