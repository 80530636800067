<div [class.collapsed]="isCollapsed$ | async"
     [class.dropdown-open]="dropdownOpen$ | async"
     [ngClass]="levelClass"
     class="sidenav-item">
  <div *ngIf="item.type === 'subheading'" [ngClass]="item.customClass"
       class="subheading">{{ item.name }}
  </div>

  <ng-container *ngIf="item.type !== 'subheading'">
    <ng-container
        *ngIf="item.subItems?.length > 0 || isFunction(item.routeOrFunction); then functionOrSubItems; else link"></ng-container>
  </ng-container>

  <ng-template #functionOrSubItems>
    <a (click)="handleClick()"
       class="sidenav-item-link"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
  </ng-template>

  <ng-template #link>
    <div [routerLinkActiveOptions]="{ exact: item.pathMatchExact || false }"
       class="sidenav-item-link"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple
       >
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
  </div>
  </ng-template>

  <ng-template #linkContent>
    <!-- <mat-icon *ngIf="level === 0" class="icon">{{ item.icon }}</mat-icon> -->
    <div *ngIf="level > 0" class="text-icon" fxFlex="none">{{ getTextIcon(item) }}</div>

    <span class="name padding-0 fix-click-item"   (click)="redirectTo()">{{ item.name }} </span>

    <span fxFlex><!-- fill space --></span>

    <ng-container *ngIf="item.buttons?.length>0">
      <mat-icon *ngFor="let elem of item.buttons"
                matTooltip="{{elem.tooltipText}}"
                [routerLink]="elem.redirectTo"
                matRipple
                class="spaced-icon"
      >
        {{elem.icon}}
      </mat-icon>
    </ng-container>
    <span *ngIf="item.badge" [style.background-color]="item.badgeColor" class="badge">{{ item.badge }}</span>
    <mat-icon *ngIf="item.subItems && item.subItems.length > 0" [class.rotate]="dropdownOpen$ | async"
              class="expand-indicator">
      expand_more
    </mat-icon>
  </ng-template>


  <div [@dropdownOpen]="dropdownOpen$ | async" class="dropdown" fxLayout="column">
    <fury-sidenav-item *ngFor="let subItem of item.subItems" [item]="subItem" [level]="level+1"></fury-sidenav-item>
  </div>
</div>
