import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading-popup',
  templateUrl: './loading-popup.component.html',
  styleUrls: ['./loading-popup.component.scss']
})
export class LoadingPopupComponent implements OnInit {

  @Input()
  loadingMsg: string;

  constructor() { }

  ngOnInit(): void {
  }

}
