import {Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {SidebarDirective} from '../../@fury/shared/sidebar/sidebar.directive';
import {SidenavService} from './sidenav/sidenav.service';
import {filter, map, startWith} from 'rxjs/operators';
import {ThemeService} from '../../@fury/services/theme.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {checkRouterChildsData} from '../../@fury/utils/check-router-childs-data';
import {NameRoutePath} from '../_type/name-route-path.type';
import {MatIconRegistry} from '@angular/material/icon';
import {SessionService} from '../_service/session.service';

@Component({
  selector: 'fury-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  @ViewChild('configPanel', {static: true}) configPanel: SidebarDirective;
  private role;

  sidenavOpen$ = this.sidenavService.open$;
  sidenavMode$ = this.sidenavService.mode$;
  sidenavCollapsed$ = this.sidenavService.collapsed$;
  sidenavExpanded$ = this.sidenavService.expanded$;
  quickPanelOpen: boolean;
  allRoles: string[];

  sideNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'side'));
  topNavigation$ = this.themeService.config$.pipe(map(config => config.navigation === 'top'));
  toolbarVisible$ = this.themeService.config$.pipe(map(config => config.toolbarVisible));
  toolbarPosition$ = this.themeService.config$.pipe(map(config => config.toolbarPosition));
  footerPosition$ = this.themeService.config$.pipe(map(config => config.footerPosition));

  scrollDisabled$ = this.router.events.pipe(
    filter<NavigationEnd>(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.scrollDisabled))
  );

  constructor(private sidenavService: SidenavService,
              private themeService: ThemeService,
              private route: ActivatedRoute,
              private router: Router,
              private sessionService: SessionService) {
  }


  ngOnInit(): void {

    this.role = this.sessionService.getSession('role');
    this.sidenavService.items = [];
    this.allRoles = ['root', 'sys-admin', 'company-admin', 'company-editor', 'company-rep'];

    this.sidenavService.addItems([
      {
        name: 'ADMIN PANEL',
        position: 0,
        type: 'subheading',
        customClass: 'first-subheading',

      },
      {
        name: 'Dashboard',
        routeOrFunction: '/',
        icon: 'dashboard',
        position: 10,
        pathMatchExact: true,
        rolePermission: this.rolePermissionsFor('Dashboard')
      },
      {
        name: 'Companies',
        // icon: 'computer',
        position: 20,
        rolePermission: this.rolePermissionsFor('Company'),
        buttons: [
          {
            redirectTo:   NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
                NameRoutePath.company + NameRoutePath._root + NameRoutePath.companyList,
            icon: 'format_list_bulleted',
            tooltipText: 'Company list',
          },
          {
            redirectTo:  NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
                NameRoutePath.company + NameRoutePath._root + NameRoutePath.companyNew,
            icon: 'add_circle_outline',
            tooltipText: 'New Company',
          }

        ],
        rootTitleNavigate: NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
            NameRoutePath.company + NameRoutePath._root + NameRoutePath.companyList
      },
      {
        name: 'Users',
        // icon: 'contacts',
        position: 30,
        rolePermission: this.rolePermissionsFor('Users'),
        buttons: [
          {
            redirectTo:   NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
              NameRoutePath.user + NameRoutePath._root + NameRoutePath.userList,
            icon: 'format_list_bulleted',
            tooltipText: 'User list',
          },
          {
            redirectTo:  NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
              NameRoutePath.user + NameRoutePath._root + NameRoutePath.userNew,
            icon: 'add_circle_outline',
            tooltipText: 'New User',
          }

        ],
        rootTitleNavigate: NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
          NameRoutePath.user + NameRoutePath._root + NameRoutePath.userList
      },
      {
        name: 'Documents',
        // icon: 'description',
        position: 50,
        rolePermission: this.rolePermissionsFor('Documents'),
        buttons: [
          {
            redirectTo:   NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
              NameRoutePath.document + NameRoutePath._root + NameRoutePath.documentList,
            icon: 'format_list_bulleted',
            tooltipText: 'Document list',
          },
          {
            redirectTo:  NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
              NameRoutePath.document + NameRoutePath._root + NameRoutePath.documentUpload,
            icon: 'add_circle_outline',
            tooltipText: 'New Document',
          }

        ],
        rootTitleNavigate: NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
          NameRoutePath.document + NameRoutePath._root + NameRoutePath.documentList
      },
      {
        name: 'Sets',
        //icon: 'collections_bookmark',
        position: 60,
        rolePermission: this.rolePermissionsFor('Set'),
        buttons: [
          {
            redirectTo:   NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
              NameRoutePath.set + NameRoutePath._root + NameRoutePath.setList,
            icon: 'format_list_bulleted',
            tooltipText:'Sets list',
          },
          {
            redirectTo:  NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
              NameRoutePath.set + NameRoutePath._root + NameRoutePath.setNew,
            icon: 'add_circle_outline',
            tooltipText: 'New Set',
          }

        ],
        rootTitleNavigate: NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
          NameRoutePath.set + NameRoutePath._root + NameRoutePath.setList
      },
      {
        name: 'Email Templates',
        position: 70,
        rolePermission: this.rolePermissionsFor('Templates'),
        buttons: [
          {
            redirectTo:   NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
              NameRoutePath.emailTemplate + NameRoutePath._root + NameRoutePath.templateList,
            icon: 'format_list_bulleted',
            tooltipText: 'Template list',
          },
          {
            redirectTo:  NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
              NameRoutePath.emailTemplate + NameRoutePath._root + NameRoutePath.templateNew,
            icon: 'add_circle_outline',
            tooltipText: 'New Template',
          }

        ],
        rootTitleNavigate: NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
        NameRoutePath.emailTemplate + NameRoutePath._root + NameRoutePath.templateList
      },
      {
        name: 'Email Templates',
        position: 80,
        rolePermission: this.rolePermissionsFor('CustomerTemplates'),
        buttons: [
          {
            redirectTo:   NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
                NameRoutePath.emailTemplate + NameRoutePath._root + NameRoutePath.templateList,
            icon: 'format_list_bulleted',
            tooltipText: 'Template list',
          },
          {
            redirectTo:  NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
                NameRoutePath.customerEmailTemplate + NameRoutePath._root + NameRoutePath.customerTemplateNew,
            icon: 'add_circle_outline',
            tooltipText: 'New Template',
          }

        ],
        rootTitleNavigate: NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
            NameRoutePath.emailTemplate + NameRoutePath._root + NameRoutePath.templateList
      },
      {
        name: 'Metrics',
        position: 90,
        rolePermission: this.rolePermissionsFor('Metrics'),
        buttons: [
          {
            redirectTo:   NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
                NameRoutePath.metrics + NameRoutePath._root + NameRoutePath.getMetrics,
            icon: 'format_list_bulleted',
            tooltipText: 'Get reports',
          }

        ],
        rootTitleNavigate: NameRoutePath._root + NameRoutePath._appWrapper + NameRoutePath._root +
            NameRoutePath.metrics + NameRoutePath._root + NameRoutePath.getMetrics
      },
      /*{
        name: 'Statistic Log',
        icon: 'insert_chart',
        position: 90,
        rolePermission: this.rolePermissionsFor('Statistic Log'),
        subItems: [
          {
            name: 'List',
            routeOrFunction: '/statistic-log/list',
            position: 10
          },
          {
            name: 'New',
            routeOrFunction: '/statistic-log/new',
            position: 20
          }
        ]
      },*/
      {
        name: 'Carts',
        routeOrFunction: '/carts',
        icon: 'shopping_cart',
        position: 100,
        rolePermission: this.rolePermissionsFor('Carts'),
      },
      {
        name: 'Reports',
        routeOrFunction: '/reports',
        icon: 'storage',
        position: 110,
        rolePermission: this.rolePermissionsFor('Reports'),
      }

    ]);
  }


  rolePermissionsFor(section: string) {

    if (!!this.role) {
      const sec = section.trim().toLowerCase();

      const roleName = this.role.template ? this.role.template.name : this.role.name;
      switch (sec) {
        case 'dashboard': {
          return this.allRolesWithout(this.allRoles).includes(roleName);
        }
        case 'company': {
          return this.allRolesWithout(['company-rep', 'company-admin', 'company-editor']).includes(roleName);
        }
        case 'users': {
          return this.allRolesWithout(['sys-admin', 'company-rep', 'company-editor']).includes(roleName);
        }
        case 'documents': {
          return this.allRolesWithout(['sys-admin', 'company-rep']).includes(roleName);
        }
        case 'set': {
          return this.allRolesWithout(['sys-admin', 'company-rep']).includes(roleName);
        }
        case 'templates': {
          return this.allRolesWithout(['company-rep', 'company-admin', 'company-editor']).includes(roleName);
        }
        case 'customertemplates': {
          return this.allRolesWithout(['sys-admin', 'company-rep']).includes(roleName);
        }
        case 'statistic log': {
          return this.allRolesWithout(this.allRoles).includes(roleName); // removed for all, to be defined
        }
        case 'carts': {
          return this.allRolesWithout(this.allRoles).includes(roleName); // removed for all, to be defined
        }
        case 'metrics': {
          return this.allRolesWithout(['sys-admin', 'company-rep']).includes(roleName);
        }

        default: {
          return false;

        }
      }

    } else {
      return false;
    }
  }

  allRolesWithout(notAllowed: string[] = []) {
    const roles =  ['root', 'sys-admin', 'company-admin', 'company-editor', 'company-rep'];

    for (const role of notAllowed) {
      const index = roles.indexOf(role.trim().toLowerCase());
      if (index > -1) {
        roles.splice(index, 1);
      }
    }

    return roles;
  }

  openQuickPanel() {
    this.quickPanelOpen = true;
  }

  openConfigPanel() {
    this.configPanel.open();
  }

  closeSidenav() {
    this.sidenavService.close();
  }

  openSidenav() {
    this.sidenavService.open();
  }

  ngOnDestroy(): void {
  }
}
