import {Injectable} from '@angular/core';
import {BaseService} from '../_util/base.service';
import _utils from '../_util/_utils';
import {NGXLogger} from 'ngx-logger';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiResponse} from '../_type/api-response.type';
import {User} from '../pages/user/model/user.model';
import {Company} from '../pages/company/model/company.model';
import {Role} from '../pages/role/model/role.model';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends BaseService {
    private readonly apiPath = _utils.genApiPath('profile');

    constructor(
        logger: NGXLogger,
        private readonly http: HttpClient,
    ) { super(logger); }

    getUser() {
        return this.http.get<ApiResponse<User>>(
            this.apiPath + '/user'
        );
    }

    getCompany() {
        return this.http.get<ApiResponse<Company>>(
            this.apiPath + '/company'
        );
    }

    getRole() {
        return this.http.get<ApiResponse<Role>>(
            this.apiPath + '/role'
        );
    }

    newPassword(password: string, storedToken: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'new-authorization': password,
                Authorization: 'Bearer ' + storedToken,
            })
        };
        return this.http.post<ApiResponse<string>>(
            this.apiPath + '/password', undefined, httpOptions
        );
    }

    logOut() {
        return this.http.delete<ApiResponse<any>>(
            this.apiPath
        );
    }
}
