export const environment = {
  production: false,
  name: 'dev',
  baseUrl: 'https://dev-api.cleverlit.com',
  kioskUrl: 'https://dev-kiosk.cleverlit.com',
  port: 3000,
  apiHead: 'v1/',
  includePortOnLinks: false,
  usePort: false,
};
