import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { NotificationService } from '../../notification/service/notification.service';
import NotificationType from '../../notification/type/notification.type';
import { UserMsg } from '../../../_type/user-msg';
import { ApiError } from '../../../_type/api-error.type';
import { HttpStatusEnum } from '../../../_type/http-status-number.type';
import { Notification } from '../../notification/model/notification.model';
import { Observable, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../authentication/service/auth.service';
import { SessionService } from '../../../_service/session.service';
import { ApiResponse } from '../../../_type/api-response.type';
import { NameRoutePath } from '../../../_type/name-route-path.type';

@Component({
  selector: 'fury-app-hash-handler',
  templateUrl: './hash-handler.component.html',
  styleUrls: ['./hash-handler.component.scss']
})
export class HashHandlerComponent implements OnInit, OnDestroy {
  state: NotificationType | 'unsubscribe';
  enumNotificationType = NotificationType;
  msg: string;
  private notification: Notification;
  private notificationType: NotificationType;
  protected unsubscribe: Subject<void> = new Subject();
  anyError = false;

  constructor(
    private readonly logger: NGXLogger,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly authService: AuthService,
    private readonly sessionService: SessionService,
  ) { }

  ngOnInit() {
    this.unsubscribeOnDestroy(
      this.route.params
    ).subscribe({
      next: this.parseHash.bind(this),
    });
  }

  /**
   * Function that parses hash that must be in the url.
   * @param params
   */
  private parseHash(params: Params) {
    /* First, decode hash from base64 */
    const _hash = atob(params.hash);
    if (_hash.includes(':')) {
      /* hash must contain a notification id and a token separated by two dots */
      const _hashParts = _hash.split(':');
      const idToken = {
        _id: _hashParts[0],
        token: _hashParts[1],
      };
      this.notification = idToken;
      this.logger.log(JSON.stringify(idToken));
      // this._log('HashHandlerComponent', 'parseHash', 'hash parsed', idToken);
      /* check notification */
      this.unsubscribeOnDestroy(
        this.notificationService.getByIdToken(idToken)
      ).subscribe({
        next: this.onNotificationExists.bind(this, params.hash),
        error: this.notificationGetByIdTokenError.bind(this),
      });
    } else {
      this.linkInvalidExpiredError();
    }
  }

  private onNotificationExists(hash: string, notification: ApiResponse<Notification>) {
    this.logger.log(JSON.stringify(notification));
    this.logger.log(JSON.stringify(hash));
    this.notificationType = notification.data.type;
    /* if notification exists and is valid, log in with this hash
    *  This logIn is only with Authorization header and is valid for only 15 minutes */
    this.unsubscribeOnDestroy(
      this.authService.logInByNotification(hash)
    ).subscribe({
      next: this.onLoginByNotification.bind(this),
      error: this.onLoginByNotificationError.bind(this)
    });
  }

  private notificationGetByIdTokenError(error: ApiError) {
    this.logger.log(JSON.stringify(error));
    if (error.error
      && error.error.statusCode === HttpStatusEnum.NOT_FOUND) {
      this.linkInvalidExpiredError();
    } else {
      // this._error('HashHandlerComponent', 'notificationGetByIdTokenError', error);
      this.anyError = true;
    }
  }
  private linkInvalidExpiredError() {
    this.anyError = true;
    this.state = NotificationType.unknown;
    this.msg = UserMsg.linkInvalidExpired;
  }

  private onLoginByNotification(response: ApiResponse<string>) {
    this.logger.log(JSON.stringify(response));
    /* Api will respond with a JSW token, store it as a ordinary session */
    this.sessionService.storeNewSessionToken(response.data);
    this.sessionService.loadSession()
      .subscribe({
        next: this.onLoadSessionSuccessful.bind(this),
        error: this.onLoadSessionError.bind(this),
      });
  }

  private onLoginByNotificationError(error: ApiError) {
    this.logger.log(JSON.stringify(error));
  }

  private onLoadSessionSuccessful() {
    /* after store session, redirect to new password form or confirm email, depending on notification type.
    Send notification id because we need update it as completed */
    if (this.notificationType === this.enumNotificationType.confirmEmail) {
      this.router.navigate(['/confirm-email'], { queryParams: { notification: this.notification._id }, skipLocationChange: true });
    } else {
      this.router.navigate(['/new-password'], { queryParams: { notification: this.notification._id }, skipLocationChange: true });
    }
  }

  private onLoadSessionError(error: ApiError) {
    this.logger.log(error);
  }

  protected unsubscribeOnDestroy<T>(observable: Observable<T>, extraTakeUntilNotifier?: Observable<any>): Observable<T> {
    if (extraTakeUntilNotifier) {
      return observable.pipe(
        delay(0),
        takeUntil(extraTakeUntilNotifier),
        takeUntil(this.unsubscribe),
      );
    } else {
      return observable.pipe(
        delay(0),
        takeUntil(this.unsubscribe),
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
