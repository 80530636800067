import { Injectable } from '@angular/core';
import { BaseService } from '../../../_util/base.service';
import { NGXLogger } from 'ngx-logger';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { IdTokenDto } from '../dto/id-token.dto';
import { ApiResponse } from '../../../_type/api-response.type';
import NotificationType from '../type/notification.type';
import _utils from '../../../_util/_utils';
import {Company} from '../../company/model/company.model';
import {NewNotificationDto} from '../dto/new-notification.dto';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends BaseService {

    private readonly apiPath = _utils.genApiPath('notification');

    constructor(
        logger: NGXLogger,
        private readonly http: HttpClient,
    ) { super(logger); }

    // POST END-POINTS

    // @Post()
    // async newNotification(@CompanyD() company: Company, @UserD() user: User, @Body() newNotificationDto: NewNotificationDto) {
    //     return await this.notificationService.newNotification(company._id, user._id, newNotificationDto);
    // }

    // @Post()
    newNotification(newNotificationDto: NewNotificationDto) {
        return this.http.post<ApiResponse<{ _id: string }>>(
            this.apiPath, newNotificationDto
        );
    }

    // PUT END-POINTS

    // /:_id/:token
    // TODO resolveNotification

    // /:id
    resolveNotification(idNotification: string, temporaryToken: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: temporaryToken,
            })
        };
        return this.http.put(this.apiPath + '/'  + idNotification, undefined, httpOptions);
    }

    // GET END-POINTS

    // @Get()
    // async getAll(@CompanyD() company: Company) {
    //     return await this.notificationService.getAll(company._id);
    // }

    // @Get('/:_id')
    // async get(@Param() params: ObjectIdDto) {
    //     return await this.notificationService.get(params._id);
    // }

    // @Get('/:_id/:token')
    getByIdToken(idToken: IdTokenDto) {
        return this.http.get<ApiResponse<{
            type: NotificationType
        }>>(
            this.apiPath + '/' + idToken._id + '/' + idToken.token
        );
    }

    // DELETE END-POINTS

    // @Delete('/unsubscribe/:email')
    unsubscribe(email: string) {
        return this.http.delete<ApiResponse<boolean>>(
            this.apiPath + '/unsubscribe/' + email
        );
    }
}
