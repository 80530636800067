import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Needed for Touch functionality of Material Components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { environment } from '../environments/environment';
import { PendingInterceptorModule } from '../@fury/shared/loading-indicator/pending-interceptor.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { RestrictByUserRole } from './_guards/restrict-by-user-role.guard';
import { SessionService } from './_service/session.service';
import { OnlyLoggedUsers } from './_guards/only-logged-users.guard';
import { AuthService } from './pages/authentication/service/auth.service';
import { JwtHttpInterceptor } from './_util/jwt-http.interceptor';
import { appInit } from './_util/app-initializer.factory';
import { OnlyGuests } from './_guards/only-guests.guard';
import { StorageService } from './_service/storage.service';
import { RoutingHistoryService } from './_service/routing-history.service';
import { GlobalErrorHandler } from './_util/global-error-handler.service';
import { LoadingPopupComponent } from './_shared/loading-popup/loading-popup.component';
import { MaterialModule } from '../@fury/shared/material-components.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BottomSheetNotificationComponent } from './_shared/bottom-sheet-notification/bottom-sheet-notification.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NGXLogger, LoggerModule, NgxLoggerLevel } from 'ngx-logger';

const httpInterceptorsProviders = [{
  provide: HTTP_INTERCEPTORS,
  useClass: JwtHttpInterceptor,
  deps: [SessionService, AuthService],
  multi: true,
}];
const appInitializerProviders = [{
  provide: APP_INITIALIZER,
  useFactory: appInit,
  deps: [SessionService, NGXLogger],
  multi: true,
}];
const guards = [
  OnlyGuests,
  OnlyLoggedUsers,
  RestrictByUserRole,
];
@NgModule({
  imports: [
    // Angular Core Module // Don't remove!
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Fury Core Modules
    AppRoutingModule,

    // Layout Module (Sidenav, Toolbar, Quickpanel, Content)
    LayoutModule,

    // Displays Loading Bar when a Route Request or HTTP Request is pending
    PendingInterceptorModule,

    LoggerModule.forRoot({
      level: NgxLoggerLevel.LOG,
      disableConsoleLogging: environment.production,
    }),
    MaterialModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.name !== 'local',
      registrationStrategy: 'registerImmediately',
    }),
  ],
  declarations: [
    AppComponent,
    LoadingPopupComponent,
    BottomSheetNotificationComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom'
      } as MatSnackBarConfig
    },
    GlobalErrorHandler, DatePipe,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    ...httpInterceptorsProviders,
    ...appInitializerProviders,
    ...guards,
    SessionService,
    AuthService,
    StorageService,
    RoutingHistoryService,
    MatBottomSheet,
  ],
})
export class AppModule { }
