import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingPopupService {

  onStart = new EventEmitter<string>();
  onStop = new EventEmitter<any>();

  constructor() { }

  startLoading(message?: string) {
    this.onStart.emit(message);
  }

  stopLoading() {
    this.onStop.emit();
  }
}
