import { Injectable } from '@angular/core';
import { BaseService } from '../../../_util/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginDto } from '../dto/login.dto';
import { ApiResponse } from '../../../_type/api-response.type';
import { NGXLogger } from 'ngx-logger';
import _utils from '../../../_util/_utils';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService {

    readonly apiPath = _utils.genApiPath('auth');

    constructor(
        logger: NGXLogger,
        private readonly http: HttpClient,
    ) { super(logger); }

    logIn(loginDto: LoginDto) {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: btoa(loginDto.user + ':' + loginDto.password),
            })
        };
        return this.http.post<ApiResponse<string>>(
            this.apiPath, undefined, httpOptions
        );
    }

    logInByNotification(hash: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: hash,
            })
        };
        return this.http.post<ApiResponse<string>>(
            this.apiPath + '/notification', undefined, httpOptions
        );
    }
}
