import { Injectable } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpUserEvent } from '@angular/common/http';
import { SessionService } from '../_service/session.service';
import { AuthService } from '../pages/authentication/service/auth.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {
  constructor(
    private readonly sessionService: SessionService,
    private readonly authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    if (!this.excludeRequest(request)
      && this.sessionService.existsStoredToken) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.sessionService.getStoredSessionToken(),
        }
      });
    }
    return next.handle(request);
  }

  private excludeRequest(request: HttpRequest<any>): boolean {
    return request.url.includes(this.authService.apiPath)
      || !request.url.includes(environment.baseUrl);
  }
}
