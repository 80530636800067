export enum NameRoutePath {
    /** '' */
    _anyRoute = '**',
    /** '/' */
    _root = '/',
    /** '' */
    _empty = '',
    _default = 'home',
    _hash = 'hash',
    /** 'login' */
    _appWrapper = 'app',
    login = 'login',
    /** 'new password' */
    newPassword = 'new-password',
    /** 'confirm email' */
    confirmEmail = 'confirm-email',
    /** '/app/home' */
    home = 'home',
    /** '/app/user */
    user = 'user',
    /** '/app/user/list */
    userList = 'list',
    /** '/app/user/view */
    userView = 'view',
    /** '/app/user/new */
    userNew = 'new',
    /** '/app/user/edit */
    userEdit = 'edit',
    /** '/app/company */
    company = 'company',
    /** '/app/company/list */
    companyList = 'list',
    /** '/app/company/view */
    companyView = 'view',
    /** '/app/company/new */
    companyNew = 'new',
    /** '/app/company/edit */
    companyEdit = 'edit',
    /** '/app/role */
    role = 'role',
    /** '/app/role/list */
    roleList = 'list',
    /** '/app/role/view */
    roleView = 'view',
    /** '/app/role/new */
    roleNew = 'new',
    /** '/app/role/edit */
    roleEdit = 'edit',
    /** '/app/document */
    document = 'document',
    /** '/app/document/list */
    documentList = 'list',
    /** '/app/document/view */
    documentView = 'view',
    /** '/app/document/new */
    documentNew = 'new',
    /** '/app/document/upload */
    documentUpload = 'upload',
    /** '/app/document/edit */
    documentEdit = 'edit',
    /** '/app/document-set */
    documentSet = 'document-set',
    /** '/app/document-set/list */
    documentSetList = 'list',
    /** '/app/document-set/view */
    documentSetView = 'view',
    /** '/app/document-set/new */
    documentSetNew = 'new',
    /** '/app/document-set/edit */
    documentSetEdit = 'edit',
    /** '/app/event */
    event = 'event',
    /** '/app/event/list */
    eventList = 'list',
    /** '/app/event/view */
    eventView = 'view',
    /** '/app/event/new */
    eventNew = 'new',
    /** '/app/event/edit */
    eventEdit = 'edit',
    /** '/app/statistic-log */
    statisticLog = 'statistic-log',
    /** '/app/statistic-log/list */
    statisticLogList = 'list',
    /** '/app/statistic-log/view */
    statisticLogView = 'view',
    /** '/app/statistic-log/event-report */
    statisticLogEventReport = 'event-report',
    /** '/app/cart */
    cart = 'cart',
    /** '/app/cart/list */
    cartList = 'list',
    /** '/app/cart/view */
    cartView = 'view',
    /** '/app/report */
    report = 'report',
    /** '/app/report/list */
    reportList = 'list',
    /** '/app/report/view */
    reportView = 'view',
    set = 'set',
    setList = 'setList',
    setNew = 'setNew',
    setAddDocs = 'setAddDocs',
    emailTemplate= 'emailTemplate',
    templateNew = 'templateNew',
    templateList = 'templateList',
    templateEdit = 'templateEdit',
    customerEmailTemplate = 'customerEmailTemplate',
    customerTemplateNew = 'new',
    customerTemplateList = 'list',
    metrics = 'metrics',
    getMetrics = 'get'
}
