import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {SidenavService} from './layout/sidenav/sidenav.service';
import {ThemeService} from '../@fury/services/theme.service';
import {ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Platform} from '@angular/cdk/platform';
import {SplashScreenService} from '../@fury/services/splash-screen.service';
import {LoadingPopupService} from './_shared/loading-popup/loading-popup.service';
import {SwUpdate} from '@angular/service-worker';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {BottomSheetNotificationComponent} from './_shared/bottom-sheet-notification/bottom-sheet-notification.component';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  loading = false;
  loadingMessage: string;

  constructor(private sidenavService: SidenavService,
              private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              private themeService: ThemeService,
              @Inject(DOCUMENT) private document: Document,
              private platform: Platform,
              private route: ActivatedRoute,
              private splashScreenService: SplashScreenService,
              private loadingService: LoadingPopupService,
              private swUpdate: SwUpdate,
              private bottomSheet: MatBottomSheet) {
    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.themeService.setStyle(queryParamMap.get('style')));
    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    this.themeService.theme$.subscribe(theme => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.loadingService.onStart.subscribe( _msg => {
      this.loading = true;
      this.loadingMessage = _msg;
    });

    this.loadingService.onStop.subscribe( _stop => {
      this.loading = false;
      this.loadingMessage = '';
    });
  }

  ngOnInit() {
    this.swUpdate.available.subscribe(() => {

      const notificationRefresh = this.bottomSheet.open(BottomSheetNotificationComponent, {
        data: { message: 'New version available. Load New Version?', action: 'Refresh'}
      });
      notificationRefresh.afterDismissed().subscribe(data => {
        if (data && data.message === 'Refresh') {
          window.location.reload();
        }
      });
    });
  }

}
