import { NameRoutePath } from '../../_type/name-route-path.type';
import { Route, UrlMatchResult, UrlSegment } from '@angular/router';
import { HashHandlerComponent } from './component/hash-handler.component';

export const hashLinkHandlerRoute: Route = {
  matcher: routeMatcher,
  component: HashHandlerComponent,
};

export function routeMatcher(segments: UrlSegment[]): UrlMatchResult {
  switch (segments.length) {
    case 2: {
      if (segments[0].path === NameRoutePath._hash) {
        return {
          consumed: segments,
          posParams: {
            hash: segments[segments.length - 1],
          },
        };
      }
    }
  }
  return null;
}
