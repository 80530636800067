import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private readonly logger: NGXLogger,
    private readonly inject: Injector,
    private readonly ngZone: NgZone,
  ) { }

  handleError(error: any) {
    const router = this.inject.get(Router);
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        this.logger.log(error);
        this.logger.log('Redirecting...');
        this.ngZone.run(() =>
          /* TODO to-check router.navigate(["login"])*/
          window.location.href = '/login',
        );
      }
      return throwError(error);
    }

  }

}
