import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { NameRoutePath } from '../_type/name-route-path.type';
import { BaseService } from '../_util/base.service';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
@Injectable()
export class RoutingHistoryService extends BaseService {

    private history: string[] = [];
    public subject = new Subject<string>();

    constructor(
        logger: NGXLogger,
        private router: Router,
    ) { super(logger); }

    loadRouting(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this.history = [...this.history, urlAfterRedirects];
            });
        if (this.history.length > 35) {
            this.dropMostOldUrls(10);
        }
    }

    getHistory(): string[] {
        return this.history;
    }

    getPreviousUrl(offset = 1): string {
        return this.history[this.history.length - (offset + 1)];
    }

    dropMostOldUrls(numberOfUrls: number) {
        this.history = this.history.slice(numberOfUrls, this.history.length - 1);
    }

    /**
     * @param currentRoutePath Must be provided to prevent back to the same url
     */
    backOnRouteHistory(currentRoutePath: NameRoutePath) {
        let navigations = 0;
        let previousUrl: string;
        do {
            previousUrl = this.getPreviousUrl(++navigations);
        } while (previousUrl && previousUrl.includes(currentRoutePath));
        if (!previousUrl) {
            previousUrl = NameRoutePath._appWrapper + '/' + NameRoutePath.home;
        }
        this.router.navigate([previousUrl]);
    }
}
