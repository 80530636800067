import { environment } from '../../environments/environment';
import { NameRoutePath } from '../_type/name-route-path.type';
import { Color } from '@angular-material-components/color-picker';

// tslint:disable-next-line: no-namespace
namespace _utils {
  export function toValidCategoryName(str: string) {
    return str.trim()
      .replace(/[^a-zA-Z0-9 ._-]/gi, '_')
      .replace(/__+/gim, '_')
      .toLowerCase()
      .trim();
  }

  export function toValidFileName(str: string) {
    return str.trim()
      .replace(/[^-_\.0-9a-z]/gi, '_')
      .replace(/__+/, '_')
      .toLowerCase()
      .trim();
  }

  export function snakeToCamel(str: string) {
    return str.replace(
      /([-_][a-z])/g,
      (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );
  }

  export function constToSpaceCase(str: string) {
    return str.replace(/[-_]/gi, ' ')
      .toLowerCase();
  }

  export function toKebabCase(str: string) {
    return str.trim().replace(
      /([a-z0-9]*)([^a-z0-9]*)/gi,
      (matched, word, invalid, offset) => word.toLowerCase() + (
        offset + matched.length === str.length ?
          '' : '-'));
  }

  export function isRegExp(regExp, flags?) {
    try {
      return new RegExp(regExp, flags);
    } catch (e) {
      return undefined;
    }
  }

  export function order(array: any[], field: string): any[] {
    return array.sort((colA, colB) => {
      if (
        (!colA[field] || !colA[field])
        && (!colB[field] || !colB[field])) {
        return 0;
      } else if (
        colA[field] && colA[field]
        && (!colB[field] || !colB[field])) {
        return -1;
      } else if (
        colB[field] && colB[field]
        && (!colA[field] || !colA[field])) {
        return 1;
      }

      const colAWeight = calculateOrderWeight(colA[field]);
      const colBWeight = calculateOrderWeight(colB[field]);
      return colAWeight - colBWeight;
    });
  }

  function calculateOrderWeight(order: number | 'first' | 'last'): number {
    if (order === 'first') {
      return -999999;
    } else if (order === 'last') {
      return 999999;
    } else if (typeof order === 'number') {
      return order;
    } else {
      return 0;
    }
  }

  export function genApiPath(entity: string) {
    return environment.baseUrl
      + (environment.usePort ?
        (':' + environment.port)
        : '')
      + '/' + environment.apiHead + entity;
  }

  export function genRouteLink(routePath: any[]) {
    return [
      NameRoutePath._root,
      NameRoutePath._appWrapper,
    ].concat(routePath);
  }

  export function camelCase(str: string) {
    return str.trim().replace(
      /([a-z0-9])([a-z0-9]*)([^a-z0-9]*)/gi,
      (matched, letter, word, invalid, offset) => letter.trim()[
        offset === 0 ?
          'toLowerCase' : 'toUpperCase'
      ]().concat(word.trim().toLowerCase()));
  }

  export function convertHexToColor(hex, opacity) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const result = new Color(r, g, b, opacity / 100);
    return result;
  }

  export function isInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  export function isStringNullUndefinedOrEmpty(str: string) {
    return str === undefined || str === null
      || str.trim().length === 0;
  }
}
export default _utils;
