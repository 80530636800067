import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-bottom-sheet-notification',
  templateUrl: './bottom-sheet-notification.component.html',
  styleUrls: ['./bottom-sheet-notification.component.scss']
})
export class BottomSheetNotificationComponent implements OnInit {

  constructor(
    protected readonly logger: NGXLogger,
    private readonly bottomSheetRef: MatBottomSheetRef<BottomSheetNotificationComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { message: string, action: string },
  ) { }

  clearBar(): void {
    this.bottomSheetRef.dismiss({
      message: 'Cancel',
      data: this.data
    });
    event.preventDefault();
  }

  actionBar() {
    this.bottomSheetRef.dismiss({
      message: this.data.action,
      data: this.data
    });
  }

  ngOnInit() {
    this.logger.log('data received', this.data);
  }

}
