import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseGuard } from './base.guard';
import { SessionService } from '../_service/session.service';
import { NameRoutePath } from '../_type/name-route-path.type';
import { NGXLogger } from 'ngx-logger';
@Injectable()
export class OnlyLoggedUsers extends BaseGuard implements CanActivate, CanActivateChild {
    constructor(
        logger: NGXLogger,
        private sessionService: SessionService,
        private router: Router,
    ) { super(logger); }
    _verify(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        if (this.sessionService.currentUserIsLogged) {
            return true;
        } else {
            this.router.navigate([
                NameRoutePath._root,
                NameRoutePath.login,
            ]);
            return false;
        }
    }
}
