import { Injectable } from '@angular/core';
import { BaseService } from '../_util/base.service';
import { SessionService } from './session.service';
import { UserAction } from '../_type/user-action.type';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { Role } from '../pages/role/model/role.model';
import { RoleProtocol, Rule } from '../_type/role-protocol.type';
import { Permission } from '../pages/role/model/permission.model';

@Injectable()
export class RolePermissionService extends BaseService {
    constructor(
        logger: NGXLogger,
        private sessionService: SessionService,
    ) { super(logger); }
    canAccess(entityName: string);
    canAccess(rule: Rule);
    canAccess(protocol: RoleProtocol);
    canAccess(value: string | Rule | RoleProtocol): boolean | Promise<boolean> | Observable<boolean> {
        if (!value
            || (value as any).length === 0) {
            return true;
        }
        if (typeof value === 'string') {
            value = { entityName: value } as Rule;
        }
        if (!Array.isArray(value)) {
            value = [value] as RoleProtocol;
        }
        const permissions = (this.sessionService.getSession('role') as Role).permissions;
        // TODO change to use rxjs
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                const rule = value[key];
                if (!this.checkRuleRestrictions(
                    permissions, rule,
                )) {
                    return false;
                }
            }
        }
        return true;
    }
    private checkRuleRestrictions(
        permissions: Permission[],
        rule: Rule,
    ) {
        const requireMatchEntity = rule.entityName !== undefined && rule.entityName !== null;
        const requireMatchAction =
            requireMatchEntity
            && rule.restrictByAction && true;
        const requireMatchId =
            requireMatchEntity
            && rule.restrictById && true;
        if (requireMatchEntity) {
            return this.validateRule(permissions, rule, requireMatchAction, requireMatchId);
        } else {
            return true;
        }
    }
    private validateRule(
        permissions: Permission[],
        rule: Rule,
        requireMatchAction: boolean,
        requireMatchId: boolean,
    ) {
        let action;
        if (requireMatchAction) {
            action = rule.restrictByAction;
        }
        let _id;
        if (requireMatchId) {
            _id = rule.restrictById;
        }
        return this.findPermissionMatchRule(
            permissions,
            rule.entityName,
            requireMatchAction,
            action,
            requireMatchId,
            _id,
        );
    }
    private findPermissionMatchRule(
        permissions: Permission[],
        entityName: string,
        requireMatchAction: boolean,
        action: UserAction,
        requireMatchId: boolean,
        _id: string,
    ) {
        return permissions.some(permission =>
            this.matchEntityName(permission, entityName)
            && this.matchId(requireMatchId, permission, _id)
            && this.matchAction(requireMatchAction, permission, action));
    }
    private matchEntityName(permission: Permission, entityName: string) {
        return permission.entityName === entityName;
    }
    private matchId(requireMatchId: boolean, permission: Permission, _id: string) {
        return !requireMatchId
            || !permission.restrictedTo
            || permission.restrictedTo.length === 0
            || (_id && permission.restrictedTo
                .some(id => _id === id));
    }
    private matchAction(requireMatchAction: boolean, permission: Permission, action: UserAction) {
        return !requireMatchAction
            || !permission.action
            || permission.action.length === 0
            || (action && permission.action
                .includes(action));
    }
}
