import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { NameRoutePath } from './_type/name-route-path.type';
import { OnlyGuests } from './_guards/only-guests.guard';
import { OnlyLoggedUsers } from './_guards/only-logged-users.guard';
import { hashLinkHandlerRoute } from './pages/hash-handler/hash-link-handler.routes';

const routes: Routes = [
  {
    path: NameRoutePath._empty,
    redirectTo: NameRoutePath.login,
    pathMatch: 'full'
  },
  {
    path: NameRoutePath.login,
    loadChildren: () => import('./pages/authentication/login/login.module').then(m => m.LoginModule),
    canActivate: [OnlyGuests],
  },
  {
    path: NameRoutePath.newPassword,
    loadChildren: () => import('./pages/authentication/new-password/new-password.module')
      .then(m => m.NewPasswordModule),
    canActivate: [OnlyLoggedUsers],
  },
  {
    path: NameRoutePath.confirmEmail,
    loadChildren: () => import('./pages/authentication/confirm-email/confirm-email.module')
      .then(m => m.ConfirmEmailModule),
    canActivate: [OnlyLoggedUsers],
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/authentication/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: NameRoutePath._appWrapper,
    component: LayoutComponent,
    children: [
      {
        path: NameRoutePath.home,
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
      },
      {
        path: NameRoutePath.company,
        loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule),
      },
      {
        path: NameRoutePath.user,
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
      },
      {
        path: NameRoutePath.document,
        loadChildren: () => import('./pages/document/document.module').then(m => m.DocumentModule),
      },
      {
        path: NameRoutePath.set,
        loadChildren: () => import('./pages/set/set.module').then(m => m.SetModule),
      },
      {
        path: NameRoutePath.emailTemplate,
        loadChildren: () => import('./pages/email-template/email-template.module').then(m => m.EmailTemplateModule),
      },
      {
        path: NameRoutePath.customerEmailTemplate,
        loadChildren: () => import('./pages/customer-email-template/customer-email-template.module')
          .then(m => m.CustomerEmailTemplateModule),
      },
      {
        path: NameRoutePath.metrics,
        loadChildren: () => import('./pages/metrics/metrics.module').then(m => m.MetricsModule),
      },
      {
        path: 'edit-profile',
        loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfileModule),
      },
      {
        path: 'blank',
        loadChildren: () => import('./pages/blank/blank.module').then(m => m.BlankModule),
      },
    ],
    canActivate: [OnlyLoggedUsers],
    canActivateChild: [OnlyLoggedUsers],
  },
  hashLinkHandlerRoute,
  {
    path: NameRoutePath._anyRoute,
    redirectTo: NameRoutePath._appWrapper + '/' + NameRoutePath.home,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // initialNavigation: 'enabled',
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
