import { Injectable } from '@angular/core';
import { BaseGuard } from './base.guard';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RolePermissionService } from '../_service/role-permission.service';
import { NGXLogger } from 'ngx-logger';
import { RoleProtocol } from '../_type/role-protocol.type';

@Injectable()
export class RestrictByUserRole extends BaseGuard implements CanActivate, CanActivateChild {
    constructor(
        logger: NGXLogger,
        private rolePermissionService: RolePermissionService,
    ) { super(logger); }
    _verify(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const protocol = route.data && route.data.roleProtocol as RoleProtocol;
        if (protocol) {
            return this.rolePermissionService.canAccess(protocol);
        } else {
            return true;
        }
    }
}
