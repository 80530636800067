import { StorageKey } from '../_type/storage-key';

export class StorageService {
    constructor() { }

    get(key: StorageKey, jsonValue = true) {
        const value = localStorage.getItem(key);
        if (value) {
            return jsonValue ? this.parseJSONValue(value) : value;
        } else {
            return undefined;
        }
    }

    set(key: StorageKey, value: any, jsonValue = true) {
        localStorage.setItem(key, jsonValue ? JSON.stringify(value) : value);
    }

    remove(key: StorageKey) {
        localStorage.removeItem(key);
    }

    private parseJSONValue(value) {
        if (value === undefined) {
            return value;
        }
        let result = JSON.parse(value);
        if (result instanceof Error) {
            result = null;
        }
        return result;
    }
}
