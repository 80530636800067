import { Injectable } from '@angular/core';
import { BaseService } from '../../../_util/base.service';
import { environment } from '../../../../environments/environment';
import { NGXLogger } from 'ngx-logger';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../../_type/api-response.type';
import { Company } from '../model/company.model';
import { User } from '../../user/model/user.model';
import { ObjectIdDto } from '../../../_type/objectId.dto';
import { NewCompanyDto } from '../dto/new-company.dto';
import { tap } from 'rxjs/operators';
import { of } from 'rxjs';
import _utils from '../../../_util/_utils';

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends BaseService {

    private readonly apiPath = _utils.genApiPath('company');

    constructor(
        logger: NGXLogger,
        private readonly http: HttpClient,
    ) { super(logger); }

    // POST END-POINTS

    // @Post()
    newCompany(newCompanyDto: NewCompanyDto) {
        this._inMemory<ApiResponse<Company[]>>();
        return this.http.post<ApiResponse<{ _id: string }>>(
            this.apiPath, newCompanyDto
        );
    }

    // @Post('/:_id/user')
    addUser(_id, user: ObjectIdDto<User>) {
        return this.http.post<ApiResponse<boolean>>(
            this.apiPath + '/' + _id + '/user', user,
        );
    }

    // GET END-POINTS

    getAll() {
        const _inMem = this._inMemory<ApiResponse<Company[]>>('getAll');
        if (_inMem) {
            return of(_inMem);
        }
        return this.http.get<ApiResponse<Company[]>>(
            this.apiPath
        ).pipe(
            tap(companies => this._inMemory('getAll', companies))
        );
    }

    get(_id: string) {
        return this.http.get<ApiResponse<Company>>(
            this.apiPath + '/' + _id
        );
    }

    exists(field: string, value: string) {
        return this.http.head(
            this.apiPath + '?' + field + '=' + value
        );
    }

    // PUT END-POINTS

    update(companyId: string, company: Company) {
        this._inMemory<ApiResponse<Company[]>>();
        return this.http.put(
            this.apiPath + '/' + companyId, company
        );
    }

    // DELETE END-POINTS

    // @Delete('/:_id/user')
    // TODO deleteUser(@Param() params: ObjectIdDto, @Body() user: ObjectIdDto) {
    // return await this.companyService.deleteUser(params._id, user._id);
    // }

}
