import { SessionService } from '../_service/session.service';
import { NGXLogger } from 'ngx-logger';

export function appInit(
    sessionService: SessionService,
    logger: NGXLogger,
): () => void {
    logger.log('starting app...');
    return () => {
        sessionService.checkStoredToken();
        if (sessionService.existsStoredToken) {
            logger.log([
                'APP_INITIALIZER', 'appInit', 'Found a stored token'
            ]);
            return sessionService
                .loadSession()
                .toPromise();
        }
    };
}
